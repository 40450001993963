#greeting-registration-form, #greeting-list {
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    max-width: none;
  }
}

#block-type {
  list-style: none;
}

.block-wrapper {
  position: relative;
  display: block;

  input, textarea {
    width: 100%;
  }

  img {
    max-width: 100%;
    max-width: 250px;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.drag_indicator {
  position: absolute;
  top: 0;
  left: 0;
  cursor: move !important;
}

.greeting-title-preview {
  font-size: 2rem;
}

.greeting-video-wrapper {
  max-width: 100%;
}

.greeting-content-block-wrapper {
  max-width: 100%;

  img {
    max-width: 100%;
  }
}

.progress-bar-background {
  background-color: #F8F9FA;
}

.progress-bar {
  background-color: #4CAF50;
}

#block-type li {
  height: auto;
  position: relative;

  &.over {
    border: 3px dashed #0a58ca;
    background-color: #ffffff;

    div * {
      opacity: 0;
    }
  }
}
