.prepp-form-video-wrapper, {
  max-width: 100%;
}

.prepp-form-image-wrapper {
  max-width: 100%;

  img {
    max-width: 100%;
  }
}

.description {
  color: #FFFFFF;
}