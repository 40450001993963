body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #251544;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.form-control::placeholder {
  opacity: 0.3;
}

h1 {
  font-size: 1.5rem;
}

.navbar-brand img {
  width: 100px;
  height: auto;
}

.navbar-toggler {
  border-radius: 50%;
  padding: 0.25rem;
  border: 2px solid;
  color: #F8F9FA;
}

.content-wrapper {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
}

h1 {
  color: #EC6087
}

.btn-primary, .btn-primary:link, .btn-primary:visited, .btn-primary:hover, .btn-primary:active {
  border: 3px solid #EC6087 !important;
  background-color: #EC6087 !important;
  color: #ffffff;
  border-radius: 25px;
  padding: 5px 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.btn-primary-light, .btn-primary-light:link, .btn-primary-light:visited, .btn-primary-light:hover, .btn-primary-light:active {
  background-color: unset !important;
  text-transform: unset !important;
}

.btn-link-white, .btn-link-white:link, .btn-link-white:visited, .btn-link-white:hover, .btn-link-white:active {
  color: #ffffff;
}

.btn-danger, .btn-outline-danger {
  border-radius: 25px;
}

.site-link, .site-link:link, .site-link:visited, .site-link:hover, .site-link:active {
  color: #ffffff;
}

.list-group-item.active {
  background-color: #EC6087;
  border-color: #ffffff;
}

.container-fluid {
  padding-right: var(--bs-gutter-x, 1.75rem);
  padding-left: var(--bs-gutter-x, 1.75rem);
}

#footer {
  color: #FFFFFF;
  text-align: center;
  width: 100%;
    li {
      display: inline;
      margin:0 15px
  }
}